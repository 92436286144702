<template>
    <form class="form" @submit.prevent="login">
        <div class="form__inputs-content">
            <div class="form__input-form">
                <label class="form__input-label">Email</label>

                <div class="form__input-icon">
                    <img src="@/assets/icons/mdi_email-outline.svg" class="form__input-img-icon" />
                    <input type="text" placeholder="Enter your email" class="form__input form--input-padding-left"
                        v-model="loginModel.userName">

                    <div class="form__input-error" v-if="errors.fields.userName">
                        {{ errors.fields.userName[0] }}
                    </div>
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label">Password</label>

                <div class="form__input-icon">
                    <img src="@/assets/icons/solar_lock-password-linear.svg" class="form__input-img-icon" />
                    <input type="password" placeholder="Enter your password"
                        class="form__input form--input-padding-left" v-model="loginModel.password">

                    <div class="form__input-error" v-if="errors.fields.password">
                        {{ errors.fields.password[0] }}
                    </div>
                </div>
            </div>
            
            <button class="button button--primary auth__right-button-resize" v-if="!buttonLoader">
                Sign In
                <img src="@/assets/icons/ph_arrow-right.svg" class="button__icon" />
            </button>

            <ButtonTextLoader button-class="button button--primary auth__right-button-resize"
                loader-text="Verifying Information" loader-class="button-text-loader__loader-primary"
                :is-loading="buttonLoader" />
        </div>
    </form>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useClientStore } from '@/stores/ClientStore.js'
import { useSubscriptionsStore } from '@/stores/SubscriptionStore.js'
import { useHotelStatusStore } from '@/stores/HotelStatusStore.js'
import { usePanicAlertStore } from '@/stores/PanicAlertStore.js'
import { useRouter } from "vue-router"
import { loginServices } from "../Services/LoginServices.js"
import ButtonTextLoader from '../../../common/ButtonTextLoader.vue'
import axios from "axios"

const { client } = useClientStore()
const { prosafeLicence } = useSubscriptionsStore()
const { getHotelStatus } = useHotelStatusStore()
const { getTotalPendingAlerts } = usePanicAlertStore()
const { loginRequest, errors } = loginServices()
const router = useRouter()
const buttonLoader = ref(false)
const loginModel = reactive({
    userName: "",
    password: "",
    device_name: "web",
    fcm_token: null
})

async function login() {
    // * Show button loader
    buttonLoader.value = true

    // * Get X-XSRF-TOKEN/cookie
    await axios.get('/sanctum/csrf-cookie')

    // * Login request
    const response = await loginRequest(loginModel)

    // * Return error if login have errors
    if (response.hasErrors) {
        buttonLoader.value = false
        return console.error(`Error in login: ${response.message}`)
    }

    // * Get hotel status
    await getHotelStatus()

    // * Get total alerts in pending status
    await getTotalPendingAlerts()

    // * Set values into store
    client.id = response.data.userId
    client.name = response.data.name
    client.image = response.data.photo
    client.hotelId = response.data.hotelId
    client.hotel = response.data.hotelName
    client.hotelAddress = response.data.hotelAddress
    client.rolId = response.data.rolId
    client.isAuth = true

    // * Set ProSafe subscription status
    prosafeLicence.type = response.data.prosafeSubscription.type
    prosafeLicence.subscriptionId = response.data.prosafeSubscription.subscriptionId
    prosafeLicence.redirection = response.data.prosafeSubscription.redirection

    // * Redirect to Home
    router.push('/')
}
</script>