import { httpRequests } from "../../../helpers/HttpRequests";

export function loginServices() {
    const { postRequest, httpErrors } = httpRequests();
    const errors = httpErrors;

    function loginRequest(loginModel) {
        return postRequest('/web_login', loginModel);
    }

    return { loginRequest, errors }
}